@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
html,
body {
  background: black;
}

.glow-text {
  color: white;
  text-shadow: 2px 0 20px rgba(22, 182, 228, 0.3),
    -2px 0 20px rgba(238, 117, 225, 0.3), 0 2px 20px rgba(22, 182, 228, 0.3),
    0 -2px 20px rgba(238, 117, 225, 0.3);
}

.social-icon {
  height: 24px;
  transition: box-shadow 0.3s ease-in-out;
}
.social-icon:hover {
  background: none;
  filter: drop-shadow(0 0 5px rgba(238, 117, 225, 1));
}

@media screen and (min-height: 1000px) {
  .darken-bg {
    opacity: 0;
  }
}

@media screen and (max-height: 999px) {
  .darken-bg {
    opacity: 0.6;
  }
}

.darken-bg {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.content {
  z-index: 1;
  overflow: hidden;
}
.footer {
  z-index: 1;
}
.bg-fix {
  z-index: -1;
  background-size: auto 100%;
}

.box {
  /* background-color: rgba(14, 126, 136, 0.8); */
  border-radius: 10px;
  padding: 10px;
}
